import React, { useEffect, useState } from "react";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Contact, Popup } from "../../../components/popup";
import { EnterpriseData } from "../../../data/data";
import OfferSlider from "../../../components/offer-slider";
import "aos/dist/aos.css";

const AOS = require("aos");

const EnterpriseSolutions = () => {
  const [popupShow, setPopupShow] = useState(false);
  const [popupShowContact, setPopupShowContact] = useState(false);

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };

  const handlePopupModalContact = () => {
    setPopupShowContact(!popupShowContact);
  };

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  }, []);

  return (
    <Layout>
      <div className="wrapper">
        <section className="serviceHeader enterpriseSolutionsHeader">
          <div className="innerPageNavigation">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/services/enterprise-solutions">
                  Enterprise solutions
                </Link>
              </li>
            </ul>
          </div>
          <div className="serviceContainer">
            <div className="heroContent">
              <h1>Enterprise Solutions</h1>
              <p>
                Trusted by over 200 startups and other businesses. An agile shop
                for excellence in enterprise solutions
              </p>
              <button className="btn heroBtn" onClick={handlePopupModalContact}>
                Get an estimate
              </button>
            </div>
            <div className="servicePartner">
              <ul>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/mobility-solutions">
                      Mobility Solutions{" "}
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/data-solutions">
                      Data Solutions{" "}
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/cloud-solutions">
                      Cloud Solutions{" "}
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/qa-testing">
                      QA & Testing{" "}
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="serviceDetailsSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="serviceDetailsImage">
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="cardgradient"
                        src="../../../images/servicedetails/service-details-1.svg"
                      />
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="mobile-cardgradient"
                        src="../../../images/servicedetails/service-details-1.svg"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceContent">
                      <p>
                        <strong>
                          With 18 years of experience in enterprise
                        </strong>{" "}
                        application development, Microservice architecture,
                        Enterprise automation, Big Data Service & Predictive
                        Analytics, Blockchain, and E-commerce,{" "}
                        <strong>Xminds can help you achieve your goals.</strong>
                      </p>
                      <p>
                        By re-engineering solutions of various complexity, we
                        assist enterprises in becoming a worldwide success.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="solutionSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="row">
                <div className="col-lg-6">
                  <h1>
                    <span className="circle"></span>
                    <span className="titleName">
                      Why choose Xminds for enterprise solutions?
                    </span>
                  </h1>
                  <div className="solutionPoint">
                    <div
                      className="valuePoint"
                      data-aos-delay="100"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="5"
                    >
                      <h5>01</h5>
                      <p>State of the art development platforms & tools</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="10"
                    >
                      <h5>02</h5>
                      <p>
                        Domain knowledge & domain related investments at the
                        start of each project
                      </p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="1000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="15"
                    >
                      <h5>03</h5>
                      <p>
                        Mature & continuously improving design and programming
                        techniques
                      </p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="1500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="20"
                    >
                      <h5>04</h5>
                      <p>
                        Continuous & complete project management working in
                        concert with the client
                      </p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="2000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="25"
                    >
                      <h5>05</h5>
                      <p>
                        Expert core committee to preview design & programming
                        activities
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="solutionBox"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="serviceofferSection"
          id="enterpriseApplicationDevelopment"
        >
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>What we offer?
              </h1>
              <div className="testimonialService">
                <div className="serviceContainer">
                  <div className="authorRow">
                    <OfferSlider data={EnterpriseData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contactSection serviceContactSeaction">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="contactRelative">
                <div className="contactTitle">
                  <div className="casestudySlider">
                    <h1>
                      <span className="circle"></span>
                      <span className="titleName">
                        We love to listen to your requirements
                      </span>
                    </h1>
                  </div>
                </div>
                
                  <Contact
                    type="static"
                    handleChange={handlePopupModal}
                    key={[popupShow, popupShowContact]}
                  />
              </div>
            </div>
          </div>
        </section>
        <section className="ourofficeSection serviceOtherSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>Our other services
              </h1>
              <div className="serviceOtherContainer">
                <div className="row">
                  <div className="servicePartner">
                    <ul>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/mobility-solutions">
                            Mobility Solutions{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/data-solutions">
                            Data Solutions{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/cloud-solutions">
                            Cloud Solutions{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/qa-testing">
                            QA & Testing{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
      <Popup
        show={popupShowContact}
        handleClose={handlePopupModalContact}
        body="contactForm"
      />
    </Layout>
  );
};

export const Head = () => (
  <Seo
    title="Efficient Enterprise application development Services | Xminds"
    description="We deliver the best enterprise application development services. Our latest digital transformation solutions can help businesses fulfil their goals."
    url="https://www.xminds.com/services/enterprise-solutions/"
  />
);

export default EnterpriseSolutions;
